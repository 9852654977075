// PrivacyPolicy.js
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <h2>Privacy Policy</h2>
      
      <p>
        At <strong>Alt Account Control</strong>, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you use our service.
      </p>

      <h3>1. Information We Collect</h3>
      <p>
        We collect the following information when you use our services:
        <ul>
          <li><strong>Personal Information:</strong> When you create an account, we collect your username, email address, and password.</li>
          <li><strong>Subscription Information:</strong> When you subscribe to one of our plans, we collect your payment information through our payment provider (e.g., Stripe).</li>
          <li><strong>Usage Data:</strong> We collect information about how you interact with our platform, such as login times, actions taken (e.g., managing Minecraft accounts), and error reports.</li>
        </ul>
      </p>

      <h3>2. How We Use Your Information</h3>
      <p>
        We use the information we collect for the following purposes:
        <ul>
          <li>To provide and maintain our service, including managing user accounts and subscriptions.</li>
          <li>To improve our platform and enhance the user experience.</li>
          <li>To send service-related emails (e.g., account verification, password recovery).</li>
          <li>To comply with legal obligations, such as keeping records for accounting and tax purposes.</li>
        </ul>
      </p>

      <h3>3. Data Sharing</h3>
      <p>
        We do not sell or share your personal information with third parties, except for:
        <ul>
          <li><strong>Service Providers:</strong> We use third-party services (such as Stripe for payments and Firebase for authentication) that may require access to your information in order to perform their services.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your personal information if required by law or to respond to valid requests by public authorities (e.g., a court or government agency).</li>
        </ul>
      </p>

      <h3>4. Security of Your Information</h3>
      <p>
        We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.
      </p>

      <h3>5. Your Rights</h3>
      <p>
        You have the right to:
        <ul>
          <li>Access the personal information we hold about you.</li>
          <li>Request that we update, correct, or delete your personal information.</li>
          <li>Withdraw your consent for the processing of your personal information.</li>
        </ul>
        To exercise any of these rights, please contact us at coventskjell@gmail.com.
      </p>

      <h3>6. Data Retention</h3>
      <p>
        We retain your personal information for as long as your account is active or as needed to provide you services. We may also retain your information for legal or accounting purposes as required by law.
      </p>

      <h3>7. Changes to This Privacy Policy</h3>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes by email.
      </p>

      <h3>8. Contact Us</h3>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at coventskjell@gmail.com.
      </p>

    </div>
  );
};

export default PrivacyPolicy;
