import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig'; // Import the Firebase Auth
import './Servers.css'; // Custom styling for the component

const Servers = () => {
  const [servers, setServers] = useState([]);
  const [serverName, setServerName] = useState('');
  const [serverIP, setServerIP] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch servers when the component is mounted
  useEffect(() => {
    const fetchServers = async () => {
      setLoading(true);
      try {
        const token = await auth.currentUser.getIdToken(); // Fetch the token
        const response = await fetch('/servers/getServers', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`, // Ensure token is passed
          },
        });
        
        const data = await response.json();
        if (response.ok) {
          setServers(data);
        } else {
          console.error('Error fetching servers:', data.message); // Log server error
          throw new Error(data.message);
        }
      } catch (error) {
        console.error('Error:', error.message); // Log client-side error
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchServers();
  }, []);

  const handleAddServer = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const token = await auth.currentUser.getIdToken(); // Fetch the token
      console.log('Fetched token:', token); // Log the token to verify

      const response = await fetch('/servers/addServer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Ensure token is passed
        },
        body: JSON.stringify({ serverName, serverIP }),
      });

      const data = await response.json();
      if (response.ok) {
        setServers([...servers, { id: data.serverId, serverName, serverIP, status: 'active' }]);
        setServerName('');
        setServerIP('');
      } else {
        console.error('Error response from server:', data.message); // Log server error
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error adding server:', error.message); // Log client-side error
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteServer = async (serverId) => {
    try {
      setLoading(true);
      const token = await auth.currentUser.getIdToken();
      console.log('Fetched token:', token); // Log the token to verify

      const response = await fetch(`/servers/deleteServer/${serverId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setServers(servers.filter(server => server.id !== serverId));
      } else {
        throw new Error('Failed to delete server');
      }
    } catch (error) {
      console.error('Error deleting server:', error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="servers-page">
      <div className="servers-container">
        <h2>Manage Servers</h2>
        {error && <p className="message error">{error}</p>}
        {loading && <p>Loading...</p>}
        <form onSubmit={handleAddServer} className="server-form">
          <input
            type="text"
            placeholder="Server Name"
            value={serverName}
            onChange={(e) => setServerName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Server IP"
            value={serverIP}
            onChange={(e) => setServerIP(e.target.value)}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Adding...' : 'Add Server'}
          </button>
        </form>
        <div className="servers-list">
          {servers.length > 0 ? (
            servers.map((server) => (
              <div key={server.id} className="server-item">
                <span>{server.serverName} (IP: {server.serverIP})</span>
                <button onClick={() => handleDeleteServer(server.id)} className="delete-btn">
                  Delete
                </button>
              </div>
            ))
          ) : (
            <p>No servers added yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Servers;
