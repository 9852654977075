// TermsOfService.js
import React from "react";

const TermsOfService = () => {
  return (
    <div className="terms-of-service-page">
      <h2>Terms of Service</h2>
      
      <h3>1. Agreement to Terms</h3>
      <p>
        By using <strong>Alt Account Control</strong> (“the Service”), you agree to be bound by these Terms of Service (“Terms”). If you do not agree to these Terms, you may not access or use the Service.
      </p>

      <h3>2. Changes to Terms</h3>
      <p>
        We reserve the right to modify these Terms at any time. We will notify you of any significant changes by posting the updated Terms on our website. Your continued use of the Service after any changes constitutes your acceptance of the updated Terms.
      </p>

      <h3>3. Use of the Service</h3>
      <p>
        <ul>
          <li><strong>Eligibility:</strong> You must be at least 18 years old or have parental consent to use the Service.</li>
          <li><strong>Account Responsibility:</strong> You are responsible for maintaining the confidentiality of your account login details and for all activities that occur under your account.</li>
          <li><strong>Prohibited Conduct:</strong> You agree not to use the Service for any unlawful purposes or to engage in any conduct that may harm the Service or its users.</li>
        </ul>
      </p>

      <h3>4. Subscriptions and Cancellation Policy</h3>
      <p>
        <ul>
          <li><strong>Subscription Plans:</strong> We offer various subscription plans for managing Minecraft alt accounts. The terms and pricing of these plans are available on our website.</li>

          <li><strong>Billing:</strong> By subscribing to a plan, you authorize us to charge your payment method for the subscription fee. Subscriptions renew automatically unless canceled prior to the renewal date.</li>

          <li><strong>Cancellation:</strong> You can cancel your subscription at any time via your account settings. Cancellations must be made before your next billing cycle to avoid being charged for the subsequent period. Once canceled, you will retain access to your subscription features until the end of the current billing cycle. No refunds will be provided for any unused portion of the subscription term.</li>

          <li><strong>Refunds:</strong> We do not offer refunds for partially used subscription periods. If a billing error occurs or you believe you are entitled to a refund, please contact us at coventskjell@gmail.com, and we will review your request.</li>

          <li><strong>Renewal:</strong> Your subscription will automatically renew at the end of each billing cycle unless you cancel your subscription before the renewal date.</li>
        </ul>
      </p>


      <h3>5. Intellectual Property</h3>
      <p>
        All content provided through the Service, including but not limited to software, images, and logos, is the intellectual property of Alt Account Control or its licensors. You may not copy, modify, or distribute any content without express written permission.
      </p>

      <h3>6. Limitation of Liability</h3>
      <p>
        To the fullest extent permitted by law, Alt Account Control shall not be liable for any indirect, incidental, special, or consequential damages arising out of your use of the Service. Our total liability shall not exceed the amount you paid for the subscription during the six months prior to the event giving rise to the claim.
      </p>

      <h3>7. Termination</h3>
      <p>
        We reserve the right to suspend or terminate your access to the Service at any time, without notice, for any reason, including violation of these Terms.
      </p>

      <h3>8. Governing Law</h3>
      <p>
        These Terms are governed by and construed in accordance with the laws of Belgium, without regard to its conflict of law provisions.
      </p>

      <h3>9. Contact Information</h3>
      <p>
        If you have any questions about these Terms, please contact us at coventskjell@gmail.com.
      </p>
      <h3>10. Prohibited Actions</h3>
      <p>
        You agree not to, and will not permit others to:
        <ul>
          <li>Copy, modify, or create derivative works based on the Service or any part of it.</li>
          <li>Reverse-engineer, decompile, disassemble, or otherwise attempt to discover the source code or structure, sequence, and organization of the Service.</li>
          <li>Remove or alter any copyright, trademark, or other proprietary notices displayed within the Service.</li>
          <li>Distribute, sublicense, or otherwise share the Service or any portion of it with others without our explicit consent.</li>
       </ul>
      </p>

      <h3>11. Intellectual Property Rights</h3>
      <p>
        All intellectual property rights in the Service, including but not limited to content, software, and trademarks, belong to <strong>Alt Account Control</strong>. You may not use, reproduce, or distribute any of this content without our explicit permission.
      </p>
    </div>
  );
};

export default TermsOfService;
