import React from "react";
import { Link } from "react-router-dom";
import './Home.css';

const Home = () => {
  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-overlay">
          <h1 className="hero-title">Welcome to Alt Account Control</h1>
          <p className="hero-subtitle">
            Manage your Minecraft alt accounts and teams effortlessly and securely.
          </p>
          <div className="cta-buttons">
            <Link to="/register" className="cta-button">Get Started</Link>
            <Link to="/subscription" className="cta-button">View Plans</Link>
          </div>
        </div>
      </section>

      {/* Mission/Vision Section */}
      <section className="mission-vision">
        <div className="container">
          <h2 className="section-title">Our Mission & Vision</h2>
          <div className="mv-content">
            <div className="mv-item">
              <h3>Vision</h3>
              <p>To empower Minecraft players and teams with seamless alt account management and collaboration tools, ensuring efficiency, security, and fun.</p>
            </div>
            <div className="mv-item">
              <h3>Mission</h3>
              <p>To provide a secure and user-friendly platform that simplifies Minecraft alt account and team management, making it accessible for players worldwide to connect, collaborate, and enjoy Minecraft with ease.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features">
        <h2>Our Features</h2>
        <div className="features-grid">
          <div className="feature-item">
            <i className="fas fa-user-shield feature-icon"></i>
            <h3>Account Management</h3>
            <p>Easily add and manage your Minecraft alt accounts, activate or deactivate them as needed.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-users feature-icon"></i>
            <h3>Team Collaboration</h3>
            <p>Create and manage teams, share access to Minecraft alt accounts, and collaborate effortlessly.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-credit-card feature-icon"></i>
            <h3>Subscription Plans</h3>
            <p>Choose a plan that fits your needs. Unlock more features with our affordable subscription plans.</p>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <div className="footer-container">
          <p>&copy; 2024 Alt Account Control. All rights reserved.</p>
          <div className="footer-links">
            <Link to="/terms-of-service">Terms of Service</Link>
            <span>|</span>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
