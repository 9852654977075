import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Alts from "./Alts";
import Servers from "./Servers";
import Teams from "./Teams";
import Subscription from "./Subscription";
import "./Dashboard.css";

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <div className="dashboard-nav">
        <h2>Dashboard</h2>
        <ul>
          <li>
            <Link to="/dashboard/alts">Alts</Link>
          </li>
          <li>
            <Link to="/dashboard/servers">Servers</Link>
          </li>
          <li>
            <Link to="/dashboard/teams">Teams</Link>
          </li>
          <li>
            <Link to="/dashboard/subscription">Subscription</Link>
          </li>
        </ul>
      </div>

      <div className="dashboard-content">
        <Routes>
          <Route path="alts" element={<Alts />} />
          <Route path="servers" element={<Servers />} />
          <Route path="teams" element={<Teams />} />
          <Route path="subscription" element={<Subscription />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
