import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import Modal from "react-modal";
import "./Subscription.css";

Modal.setAppElement("#root");

function Subscription() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const auth = getAuth();
  const user = auth.currentUser;

  const handleSubscribe = async (plan) => {
    if (!user) {
      setIsOpen(true);
      setMessage("You need to log in to subscribe.");
    } else if (!user.emailVerified) {
      setIsOpen(true);
      setMessage("Please verify your email before subscribing.");
    } else {
      setMessage(`You are subscribed to the ${plan} plan.`);
      setIsOpen(true);
    }
  };

  const resendVerificationEmail = async () => {
    if (user) {
      await user.sendEmailVerification();
      setMessage("Verification email sent. Please check your inbox.");
    }
  };

  return (
    <div className="subscription-page">
      <div className="subscription-header">
        <h2>Subscription Plans</h2>
        <p>Choose the plan that suits your needs:</p>
      </div>

      <div className="plans">
        <div className="plan">
          <h3>Basic Plan</h3>
          <p>€10 per month for 3 alts.</p>
          <button onClick={() => handleSubscribe("Basic")}>Subscribe to Basic</button>
        </div>
        <div className="plan">
          <h3>Premium Plan</h3>
          <p>€15 per month for 10 alts.</p>
          <button onClick={() => handleSubscribe("Premium")}>Subscribe to Premium</button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>{!user ? "Login Required" : "Email Verification Required"}</h2>
        <p>{message}</p>
        {!user ? (
          <button onClick={() => setIsOpen(false)} className="modal-button">
            Close
          </button>
        ) : (
          <>
            <button onClick={resendVerificationEmail} className="modal-button">
              Resend Verification Email
            </button>
            <button onClick={() => setIsOpen(false)} className="modal-button">
              Close
            </button>
          </>
        )}
      </Modal>
    </div>
  );
}

export default Subscription;
