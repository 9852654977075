import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy'; // Import the Privacy Policy component
import Dashboard from './components/Dashboard';
import Subscription from './components/Subscription';
import NavBar from './components/NavBar';
import { auth, db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true);

        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setUsername(userDoc.data().username);
        } else {
          setUsername('User');
        }
      } else {
        setIsAuthenticated(false);
        setUsername('');
      }
    });

    return () => unsubscribe(); // Cleanup the listener
  }, []);

  return (
    <Router>
      <div className="App">
        <NavBar isAuthenticated={isAuthenticated} username={username} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/terms-of-service" element={<TermsOfService/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/dashboard/*" element={isAuthenticated ? <Dashboard /> : <Login />} /> {/* <-- Updated */}
          <Route path="/subscription" element={<Subscription />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
