import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';
import './Alts.css';

const Alts = () => {
  const [alts, setAlts] = useState([]);
  const [servers, setServers] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [altName, setAltName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showCommandPopup, setShowCommandPopup] = useState(false);
  const [currentAltId, setCurrentAltId] = useState(null);
  const [command, setCommand] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedServer, setSelectedServer] = useState('');

  useEffect(() => {
    const fetchAlts = async () => {
      if (!auth.currentUser) {
        console.log('User not authenticated');
        return;
      }

      try {
        const altCollection = collection(db, 'alts');
        const q = query(altCollection, where('userId', '==', auth.currentUser.uid));
        const altSnapshot = await getDocs(q);
        const altList = altSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAlts(altList);
      } catch (error) {
        console.error('Error fetching alts:', error);
      }
    };

    const fetchServers = async () => {
      if (!auth.currentUser) return;

      try {
        const response = await fetch('/servers/getServers', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setServers(data);
        }
      } catch (error) {
        console.error('Error fetching servers:', error);
      }
    };

    fetchAlts();
    fetchServers();
  }, [auth.currentUser]);

  const handleAddAlt = async (e) => {
    e.preventDefault();
    if (!altName || !email || !password) {
      alert('Please fill out all fields.');
      return;
    }

    setLoading(true);
    try {
      await addDoc(collection(db, 'alts'), {
        altName,
        email,
        password,
        status: 'inactive',
        server: '',
        userId: auth.currentUser.uid
      });

      setAltName('');
      setEmail('');
      setPassword('');
      setLoading(false);

      const altCollection = collection(db, 'alts');
      const q = query(altCollection, where('userId', '==', auth.currentUser.uid));
      const altSnapshot = await getDocs(q);
      const altList = altSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAlts(altList);

    } catch (error) {
      console.error('Error adding alt:', error);
      setLoading(false);
    }
  };

  const handleActivate = async (altId, email, password) => {
    if (!selectedServer) {
      alert('Please select a server.');
      return;
    }

    try {
      const response = await fetch(`/minecraft/activateAlt/${altId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.currentUser.stsTokenManager.accessToken}`,
        },
        body: JSON.stringify({ email, password, server: selectedServer }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);
        setAlts(alts.map(alt => alt.id === altId ? { ...alt, status: 'active', server: selectedServer } : alt));
      } else {
        const error = await response.json();
        console.error(error.message);
      }
    } catch (error) {
      console.error('Error activating alt:', error);
    }
  };

  const handleDeactivate = async (altId) => {
    try {
      const response = await fetch(`/minecraft/deactivateAlt/${altId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.currentUser.stsTokenManager.accessToken}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);
        setAlts(alts.map(alt => alt.id === altId ? { ...alt, status: 'inactive', server: '' } : alt));
      } else {
        const error = await response.json();
        console.error(error.message);
      }
    } catch (error) {
      console.error('Error deactivating alt:', error);
    }
  };

  const handleSendCommand = async () => {
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await fetch(`/minecraft/sendCommand/${currentAltId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.currentUser.stsTokenManager.accessToken}`,
        },
        body: JSON.stringify({ command }),
      });

      if (response.ok) {
        const result = await response.json();
        setSuccessMessage(`Command "${command}" sent successfully!`);
        setCommand('');
        setShowCommandPopup(false);

        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);

      } else {
        const error = await response.json();
        setErrorMessage(`Error: ${error.message}`);

        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);

      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  const handleDeleteAlt = async (altId) => {
    try {
      await deleteDoc(doc(db, 'alts', altId));
      setAlts(alts.filter(alt => alt.id !== altId));
    } catch (error) {
      console.error('Error deleting alt:', error);
    }
  };

  return (
    <div className="alts-page">
      <div className="alts-container">
        <h2>Manage Minecraft Alt Accounts</h2>
        <form onSubmit={handleAddAlt} className="alt-form">
          <input
            type="text"
            placeholder="Alt Name"
            value={altName}
            onChange={(e) => setAltName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Adding...' : 'Add Alt'}
          </button>
        </form>
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <div className="alt-list">
          {alts.length === 0 ? (
            <p>No alt accounts found.</p>
          ) : (
            alts.map((alt) => (
              <div key={alt.id} className="alt-item">
                <span>{alt.altName}</span>
                <button onClick={() => handleDeleteAlt(alt.id)} className="delete-btn">
                  Delete
                </button>
              </div>
            ))
          )}
        </div>
      </div>
      {showCommandPopup && (
        <div className="command-popup">
          <h3>Send Command</h3>
          <input
            type="text"
            placeholder="Enter command"
            value={command}
            onChange={(e) => setCommand(e.target.value)}
          />
          <button onClick={handleSendCommand}>Send</button>
          <button onClick={() => setShowCommandPopup(false)}>Cancel</button>
        </div>
      )}

      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default Alts;
