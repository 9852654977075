import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import './Teams.css'; // Custom styling for the component

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [teamName, setTeamName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null); // Store selected team
  const [membersAlts, setMembersAlts] = useState([]); // Store team members and their alts
  const [servers, setServers] = useState([]); // Store servers
  const [selectedServer, setSelectedServer] = useState(''); // Store selected server

  // Fetch teams when the component is mounted
  useEffect(() => {
    const fetchTeams = async () => {
      setLoading(true);
      try {
        const token = await auth.currentUser.getIdToken(); // Fetch the token
        const response = await fetch('/teams/getTeams', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`, // Ensure token is passed
          },
        });

        const data = await response.json();
        if (response.ok) {
          setTeams(data);
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchServers = async () => {
      const token = await auth.currentUser.getIdToken(); // Fetch the token
      const response = await fetch('/servers/getServers', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`, // Ensure token is passed
        },
      });
      const data = await response.json();
      if (response.ok) {
        setServers(data); // Store servers to allow selection for each alt
      } else {
        console.error('Error fetching servers:', data.message);
      }
    };

    fetchTeams();
    fetchServers();
  }, []);

  // Function to create a new team
  const handleCreateTeam = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch('/teams/addTeam', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ teamName }),
      });

      const data = await response.json();
      if (response.ok) {
        setTeams([...teams, { id: data.teamId, teamName }]);
        setTeamName('');
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch members' alts when selecting a team
  const handleSelectTeam = async (teamId) => {
    setSelectedTeam(teamId);
    setLoading(true);
    setError(null);

    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`/teams/getTeamMembersAlts/${teamId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        setMembersAlts(data); // Store members and their alts
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Activate alt for the selected team
  const handleActivate = async (altId, email, password) => {
    if (!selectedServer) {
      alert('Please select a server.');
      return;
    }

    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`/minecraft/activateAlt/${altId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email, password, server: selectedServer }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);
        setMembersAlts(
          membersAlts.map((member) => ({
            ...member,
            alts: member.alts.map((alt) =>
              alt.id === altId ? { ...alt, status: 'active' } : alt
            ),
          }))
        );
      } else {
        const error = await response.json();
        console.error(error.message);
      }
    } catch (error) {
      console.error('Error activating alt:', error);
    }
  };

  // Deactivate an alt for the selected team
  const handleDeactivate = async (altId) => {
    if (!selectedServer) {
      alert('Please select a server.');
      return;
    }

    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`/minecraft/deactivateAlt/${altId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);
        setMembersAlts(
          membersAlts.map((member) => ({
            ...member,
            alts: member.alts.map((alt) =>
              alt.id === altId ? { ...alt, status: 'inactive' } : alt
            ),
          }))
        );
      } else {
        const error = await response.json();
        console.error(error.message);
      }
    } catch (error) {
      console.error('Error deactivating alt:', error);
    }
  };

  return (
    <div className="teams-page">
      <div className="teams-container">
        <h2>Manage Teams</h2>
        {error && <p className="message error">{error}</p>}
        {loading && <p>Loading...</p>}
        <form onSubmit={handleCreateTeam} className="team-form">
          <input
            type="text"
            placeholder="Team Name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Creating...' : 'Create Team'}
          </button>
        </form>
        <div className="teams-list">
          {teams.length === 0 ? (
            <p>No teams found.</p>
          ) : (
            teams.map((team) => (
              <div key={team.id} className="team-item" onClick={() => handleSelectTeam(team.id)}>
                <span>{team.teamName}</span>
              </div>
            ))
          )}
        </div>
        {selectedTeam && (
          <div>
            <label>Select Server:</label>
            <select value={selectedServer} onChange={(e) => setSelectedServer(e.target.value)}>
              <option value="">Select a server</option>
              {servers.map((server) => (
                <option key={server.id} value={server.serverIP}>
                  {server.serverName} ({server.serverIP})
                </option>
              ))}
            </select>
          </div>
        )}
        {selectedTeam && membersAlts.length > 0 && (
          <div className="members-alts">
            <h3>Team Members and their Alts</h3>
            {membersAlts.map((member) => (
              <div key={member.memberId} className="member-item">
                <h4>{member.username}</h4>
                {member.alts.length > 0 ? (
                  member.alts.map((alt) => (
                    <div key={alt.id} className="alt-item">
                      <span>{alt.altName} (Status: {alt.status})</span>
                      {alt.status === 'inactive' ? (
                        <button onClick={() => handleActivate(alt.id, alt.email, alt.password)}>
                          Activate
                        </button>
                      ) : (
                        <button onClick={() => handleDeactivate(alt.id)}>Deactivate</button>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No alts found for this member.</p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Teams;
