import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import './NavBar.css';

const NavBar = () => {
  const [user] = useAuthState(auth);
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle mobile menu
  const navigate = useNavigate();

  const handleLogout = async () => {
    await auth.signOut();
    localStorage.removeItem("username");
    navigate("/");
  };

  const username = localStorage.getItem("username") || "User";

  return (
    <nav className="navbar">
      <div className="navbar-header">
        <div className="navbar-logo">
          <Link to="/">
            <h1>Alt Account Control</h1>
          </Link>
        </div>
        {/* Hamburger Menu Button for Mobile */}
        <button
          className="hamburger-menu"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          ☰
        </button>
      </div>

      {/* Navigation Links */}
      <ul className={`navbar-links ${menuOpen ? "active" : ""}`}>
        <li><Link to="/" onClick={() => setMenuOpen(false)}>Home</Link></li>
        <li><Link to="/subscription" onClick={() => setMenuOpen(false)}>Subscriptions</Link></li>
        {!user ? (
          <>
            <li><Link to="/login" onClick={() => setMenuOpen(false)}>Login</Link></li>
            <li><Link to="/register" onClick={() => setMenuOpen(false)}>Register</Link></li>
          </>
        ) : (
          <>
            <li><Link to="/dashboard" onClick={() => setMenuOpen(false)}>Dashboard</Link></li>
          </>
        )}
      </ul>

      {/* User Info and Logout Button */}
      {user && (
        <div className="navbar-right">
          <span>Welcome, {username}</span>
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
